import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import get from "lodash/get";
import Img from "gatsby-image";
import Layout from "../components/post-layout";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Tags from "../components/body-tags";
import SideContent from '../components/side-components/lender-side.js'
import Compare from '../components/compare-components/dropdown-compare.js'

import heroStyles from "../components/hero.module.css";

class IndustryPost extends React.Component {
    
  render() {
    const post = get(this.props, "data.contentfulIndustry");
    const siteTitle = get(this.props, "data.site.siteMetadata.title");
    const articles = get(this, 'props.data.allContentfulLender.edges')

    console.log(post);

     const mainContent = (
       <div>
        <div className="flex" style={{flexWrap: 'wrap',}}>
                <div class="lg:w-1/4 w-full ">
            
                        <div className="js-toc sticky top-0 pt-12"></div>
                   
                </div>
                    <div class="lg:w-3/4 w-full">
                        <div className="js-toc-content px-2">
                            <img class="w-auto max-w-full" src={post.image.file.url} alt="Room Image" />
                            <h1 className="pt-4">{post.title}</h1>
                            <Tags body={post.body.json}/>
                        </div>
                    </div>
                </div>
            </div>
       
     );
    return (
      <Layout location={this.props.location} mainContent={mainContent} title = {post.title} image = {post.image.file.url} sideContent={<SideContent articles={articles} />}>
      
          <Helmet title={`${post.name} | ${siteTitle}`} />
          
      </Layout>
    );
  }
}

export default IndustryPost;

export const pageQuery = graphql`
  query IndustryBySlug($slug: String!) {

  allContentfulLender(limit: 8, sort: { fields: [title], order: DESC }) {
      edges {
        node {
        tagList {
            title
          }
          title
          slug
          description {
            description
          }
          image {
            file {
                url
            }
         }
        }
      }
    }

    contentfulIndustry(slug: { eq: $slug }) {
      title : name
      slug
      body {
        json
      }
      image {
            file {
                url
            }
         }
    }
  }
`;
